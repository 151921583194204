
import { defineComponent, computed, ref, onMounted } from "vue";
//import tableStats from "@/views/dashboard/TableStatistics.vue";
import DashboardService from "@/core/services/car/DashboardService";
import Pie from "@/views/dashboard/PieChart.vue";
import { useStore } from "vuex";
import { registration } from "@/store/stateless/store";

export default defineComponent({
  components: {
    //tableStats,
    Pie
  },
  setup() {
    const store = useStore();

    const topSalesAgent = computed(() => {
      
      var getTopSalesAgent = store.getters.getTopSalesAgent;
      if(user.value.role_id == 13 || user.value.role_id == 9)
      {
        getTopSalesAgent = getTopSalesAgent.filter(function(item) {
          return item['agent'] == user.value.id;
        });
      }

      return getTopSalesAgent;
    
    });

    /**
     * Commented on : 24-07-2023
     * Incorrect syntax, multiple calls to same api
     */
    // DashboardService.getStatistics({
    //   start_date: "2022-09-01",
    //   end_date: "2022-09-30"
    // });

    const topSalesPersonUpdated  = computed(() => {
            const datas = store.getters.getTopSalesAgent
            if (datas) {
                const value = datas.map((data) => {
                    return {
                        agent_name: data.agent_name,
                        total_leads: data.total_leads,
                        total_deals: data.total_deals,
                        new_leads: data.new_leads,
                        renewal_leads: data.renewal_leads,
                        llr_leads: data.llr_leads,
                        pending_leads: data.pending_leads,
                        untouched_customers: data.untouched_customers,
                        untouched_leads: data.untouched_leads,
                        over_due_tasks: data.over_due_tasks,
                        new_deals: data.new_deals,
                        renewal_deals: data.renewal_deals,
                        lrl_leads: data.lrl_leads,
                        lost_leads: data.lost_leads,
                        total_deals_cancelled: data.total_deals_cancelled,
                        sales_target: data.sales_target,
                        variance: data.variance,
                        quick_leads: data.quick_leads,
                        todays_task_count: data.todays_task_count,
                        current_month_tasks: data.current_month_tasks,
                        previous_month_tasks: data.previous_month_tasks,
                        cancellation_amount: data.cancellation_amount,
                        total_refund: data.total_refund,
                        deals_cancellation_payable_amount: currency(data.deals_cancellation_payable_amount),
                        total_revenue: currency(data.total_revenue),
                        closing_ratio_total: data.closing_ratio_total + '%', // (total_deals / total_leads) * 100
                        closing_ratio_new: data.closing_ratio_new + '%', // (new_deals / new_leads) * 100
                        closing_ratio_renewal: data.closing_ratio_renewal + '%', // (renewal_deals / renewal_leads) * 100
                        closing_ratio_lost_leads_and_lead_renewal_lost: data.closing_ratio_lost_leads_and_lead_renewal_lost + '%'
                    }
                })
                if(Object.keys(totalTopSalesAgent.value).length > 0) {
                    value.push(totalTopSalesAgent.value)
                }

                return value
                
            } else {
                return []
            }
        })

        const totalTopSalesAgent = computed(() => {
            const datas = store.getters.getTopSalesAgent
            if(datas) {
                const total_deals = calculateTotal(datas, 'total_deals')
                const total_leads = calculateTotal(datas, 'total_leads')
                const new_deals = calculateTotal(datas, 'new_deals')
                const new_leads = calculateTotal(datas, 'new_leads')
                const renewal_deals = calculateTotal(datas, 'renewal_deals')
                const renewal_leads = calculateTotal(datas, 'renewal_leads')
                const closing_total = roundUp((total_deals / total_leads) * 100)
                const closing_new = roundUp((new_deals / new_leads) * 100)
                const closing_renewal = roundUp((renewal_deals / renewal_leads) * 100)
                const quick_leads = calculateTotal(datas, 'quick_leads')
                const closing_ratio_ll_and_lrl = roundUp(((calculateTotal(datas, 'lost_leads') + calculateTotal(datas, 'lrl_leads')) / calculateTotal(datas, 'total_leads')) * 100)
                return {
                    agent_name: 'Total',
                    total_leads,
                    total_deals,
                    new_leads,
                    renewal_leads,
                    quick_leads,
                    variance: roundUp(calculateTotal(datas, 'variance')),
                    sales_target: calculateTotal(datas, 'sales_target'),
                    llr_leads: calculateTotal(datas, 'llr_leads'),
                    lrl_leads: calculateTotal(datas, 'lrl_leads'),
                    lost_leads: calculateTotal(datas, 'lost_leads'),
                    pending_leads: calculateTotal(datas, 'pending_leads'),
                    untouched_customers: calculateTotal(datas, 'untouched_customers'),
                    untouched_leads: calculateTotal(datas, 'untouched_leads'),
                    over_due_tasks: calculateTotal(datas, 'over_due_tasks'),
                    todays_task_count: calculateTotal(datas, 'todays_task_count'),
                    current_month_tasks: calculateTotal(datas, 'current_month_tasks'),
                    previous_month_tasks: calculateTotal(datas, 'previous_month_tasks'),
                    cancellation_amount: calculateTotal(datas, 'cancellation_amount').toFixed(2),
                    new_deals,
                    renewal_deals,
                    total_refund: calculateTotal(datas, 'total_refund'),
                    total_deals_cancelled: calculateTotal(datas, 'total_deals_cancelled'),
                    deals_cancellation_payable_amount: currency(roundUp(calculateTotal(datas, 'deals_cancellation_payable_amount'))),
                    total_revenue: currency(roundUp(calculateTotal(datas, 'total_revenue'))),
                    closing_ratio_total: isFinite(closing_total) ? `${roundUp(closing_total)}%` : `0.00%`,
                    closing_ratio_new: isFinite(closing_new) ? `${roundUp(closing_new)}%` : `0.00%`,
                    closing_ratio_renewal: isFinite(closing_renewal) ? `${roundUp(closing_renewal)}%` : `0.00%`,
                    closing_ratio_lost_leads_and_lead_renewal_lost: isFinite(closing_ratio_ll_and_lrl) ? `${roundUp(closing_ratio_ll_and_lrl)}%` : `0.00%`
                }
            }

            return {}
        })

        const calculateTotal = (data, column) => {
            return data.reduce((total, item) => {
                    if(typeof item[column] === 'string') {
                        const value = item[column].replace(',', '')
                        return total += parseFloat(value)
                    } else {
                        return total += item[column]
                    }
            }, 0)
        }

        const roundUp = (num) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

    const topSalesPerson = computed(() => {
      const datas = store.getters.getTopSalesAgent;
      if (datas) {
        return datas.map((data) => {
          return {
            agent_name: data.agent_name,
            new_leads: data.new_leads,
            untouched_leads: data.untouched_leads,
            new_deals: data.new_deals,
            total_deals: data.total_deals,
            total_revenue: data.total_revenue,
            closing_ratio_total: data.closing_ratio_total + "%"
          };
        });
      } else {
        return [];
      }
    });

    const revenueLoading = computed(() => {
      return store.getters.getRevenueLoading;
    });

    const salesTarget = computed(() => {
      return store.getters.getSalesTargetDashboard;
    });

    const policyData = computed(() => {
      return store.getters.getPolicyData;
    });

    const taskDueDateCount = computed(() => {
      return store.getters.getTaskDueDateCount;
    });

    const totalRevenue = computed(() => {
     
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseFloat(item.total_revenue));
      }, 0);
    });

    const totalRevenueTarget = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        console.log(item.revenue_target);
        return (total += parseFloat(item.revenue_target));
      }, 0);
    });

    const totalDeals = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += item.total_deals);
      }, 0);
    });

    const totalSalesTarget = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += item.sales_target);
      }, 0);
    });

    const totalVariance = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseFloat(item.variance));
      }, 0);
    });

    const totalRefund = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        const value = item.total_refund.replace(',', '')
        return (total += parseFloat(value));
      }, 0);
    });

    const totalCancellation = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return item.cancellation_amount;
      }, 0);
    });

    const totalLeads = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += item.total_leads);
      }, 0);
    });

    const totalNewLeads = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.new_leads));
      }, 0);
    });

    const totalDealsTarget = computed(() => {
      return salesTarget.value.reduce((total, item) => {
        return (total += item.total_deals);
      }, 0);
    });

    const totalTargetDeals = computed(() => {
      return salesTarget.value.reduce((total, item) => {
        return (total += item.sales_target);
      }, 0);
    });

    const totalClosingRatio = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseFloat(item.closing_ratio_total));
      }, 0);
    });

    const totalOverdue = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.over_due_tasks));
      }, 0);
    });

    const totalTasksThisMonth = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.current_month_tasks));
      }, 0);
    });

    const totalTasksPreviousMonth = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.previous_month_tasks));
      }, 0);
    });

    const totalTodayTask = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.todays_task_count));
      }, 0);
    });

    const totalQuickLeads = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.quick_leads));
      }, 0);
    });

    const totalPendingLeads = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.pending_leads));
      }, 0);
    });

    const totalRenewalLeads = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.renewal_leads));
      }, 0);
    });

    const totalLLR = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.llr_leads));
      }, 0);
    });

    const totalLRL = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.lrl_leads));
      }, 0);
    });

    const leadsThisMonth = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.leads_this_month));
      }, 0);
    });


    const totalLostLead = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.lost_leads));
      }, 0);
    });

  
    

    const totalRefundCount = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.total_refund_count));
      }, 0);
    });

    const totalCancellationCount = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.cancellation_count));
      }, 0);
    });

    const salesPerformaceChart = computed(() => {
      return store.getters.getSalesPerformanceChart;
    });

    const hasSales = computed(() => {
      if(salesPerformaceChart.value) 
        return salesPerformaceChart.value.series.reduce((a,b) => a + b, 0)

        return 0
    })

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const revenueSales = computed(() => {
      return store.getters.getRenewalSalesList;
    });

    let llrSales = computed(() => {
      return store.getters.getLlrSalesList;
    });

    //Prachi: Pasted for pie chart demo starts
    const leads = computed(() => {
      return store.getters.getStatisticsLeadBySource;
    });
    //Prachi: Pasted for pie chart demo ends

    const currency = (e) => {
      const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: registration.currency,
          maximumFractionDigits: 2,
      });

      return formatter.format(e).replace(registration.currency, "");
    }

    const topSalesPersonColumn = ref([
      {
        label: "Agent",
        prop: "agent_name",
        width: "300",
        align: "left"
      },
      {
        label: "New Leads",
        prop: "new_leads",
        width: "150",
        align: "center"
      },
      {
        label: "Deals",
        prop: "total_deals",
        width: "150",
        align: "center"
      },
      {
        label: "Untouched Leads",
        prop: "untouched_leads",
        width: "150",
        align: "center",
        className: "error"
      },
      {
        label: "Closing Ration (Total)",
        prop: "closing_ratio_total",
        width: "250",
        align: "center"
      },
      {
        label: "Total Revenue",
        prop: "total_revenue",
        width: "150",
        align: "center"
      }
    ]);

    const roundValue = (value) => {
      return value.toFixed(2);
    }

    onMounted(() => {
      console.log(getTextWidth('148426.59 / 50000', ''))
    })

    const getTextWidth = (text, font) => {
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    };

    const revenues = computed(() => {
      return `${totalRevenue.value.toFixed(2)} / ${totalSalesTarget.value * 1000}`;
    })

    const dealsTarget = computed(() => {
      return `${totalDeals.value} / ${totalSalesTarget.value}`
    })

    const leadsQuick = computed(() => {
      return `${totalNewLeads.value} | ${totalQuickLeads.value}`
    })

    const renewalLLR = computed(() => {
      return `${totalRenewalLeads.value} | ${totalLLR.value}`
    })

    const lostLeadLLR = computed(() => {
      return `${totalLRL.value} | ${totalLostLead.value}`
    })

    const hasNoData = computed(() => {
      return store.getters.getHasNoData
    })
    
    return {
      hasSales,
      registration,
      hasNoData,
      totalTopSalesAgent,
      revenues,
      dealsTarget,
      lostLeadLLR,
      getTextWidth,
      renewalLLR,
      leadsQuick,
      totalLostLead,
      leadsThisMonth,
      totalLRL,
      llrSales,
      revenueSales,
      roundValue,
      currency,
      salesTarget,
      topSalesAgent,
      totalRevenue,
      totalRevenueTarget,
      totalDeals,
      totalVariance,
      totalRefund,
      totalCancellation,
      totalSalesTarget,
      totalLeads,
      totalNewLeads,
      totalQuickLeads,
      totalPendingLeads,
      totalRenewalLeads,
      totalDealsTarget,
      totalTargetDeals,
      totalClosingRatio,
      policyData,
      taskDueDateCount,
      totalOverdue,
      totalTodayTask,
      totalTasksThisMonth,
      totalTasksPreviousMonth,
      user,
      topSalesPerson,
      topSalesPersonColumn,
      leads,
      topSalesPersonUpdated,
      salesPerformaceChart,
      totalLLR,
      totalRefundCount,
      totalCancellationCount,
      revenueLoading,
    };
  }
});
