
import { defineComponent, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import IMultiselect from "@/models/IMultiselect";
import Multiselect from "@vueform/multiselect";
import Overview from "@/views/dashboard/Overview.vue";
import DashboardService from "@/core/services/car/DashboardService";
import moment from "moment";
import Statistics from "@/views/dashboard/Statistics.vue";
import SalesPerson from "@/views/dashboard/SalesPerson.vue";
import Others from "@/views/dashboard/Others.vue";
import Underwriter from "@/views/dashboard/Underwriter.vue";
import SalesDashboard from "@/views/dashboard/SalesDashboard.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import CustomerService from "@/core/services/car/CustomerService";
import { onBeforeRouteLeave } from "vue-router";
import { ElNotification } from "element-plus";
import MarketingDashboard from "@/views/dashboard/MarketingDashboard.vue";
import AccountsDashboard from "@/views/dashboard/AccountsDashboard.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import router from "@/router";
import { registration } from "@/store/stateless/store";
import Pie from "@/views/dashboard/PieChart.vue";

// import Pie from "@/views/dashboard/PieChart.vue";
// import tableStats from "@/views/dashboard/TableStatistics.vue";

interface DashboardBodyParam {
  start_date: string;
  end_date: string;
  type: string;
}
export default defineComponent({
  name: "dashboardComponent",
  components: {
    Overview,
    Multiselect,
    Statistics,
    SalesPerson,
    Others,
    SalesDashboard,
    Underwriter,
    AddCustomerModal,
    MarketingDashboard,
    AccountsDashboard,
    Pie

    // , Pie, tableStats
  },
  data() {
    return {
      overViewTypes: [{}]
    };
  },
  created() {
    // UserService.checkToken(window.localStorage.getItem('id_token'));
  },
  setup() {
    const store = useStore();
    let type = ref<string>("today");

    const tab = ref(0);

    let daterange = ref<any>();
    const omLoading = ref(true)

    let bodyParameters = ref<DashboardBodyParam>({
      start_date: "",
      end_date: "",
      type: ""
    });

    const topSalesAgent = computed(() => {
      
      var getTopSalesAgent = store.getters.getTopSalesAgent;
      if(user.value.role_id == 13 || user.value.role_id == 9)
      {
        getTopSalesAgent = getTopSalesAgent.filter(function(item) {
          return item['agent'] == user.value.id;
        });
      }

      return getTopSalesAgent;
    
    });

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      // currentMonth();
      const nameRoute = router.currentRoute.value.name;
      if([1,14].includes(user.value.role_id) || nameRoute == 'admin-dashboard')
      {
        getToday();
      }
      else
      {
        type.value = 'month';
        currentMonth();
      }
      

      // if (localStorage.getItem("dashboardReloaded")) {
      //   // The page was just reloaded. Clear the value from local storage
      //   // so that it will reload the next time this page is visited.
      //   localStorage.removeItem("dashboardReloaded");
      // } else {
      //   // Set a flag so that we know not to reload the page twice.
      //   localStorage.setItem("dashboardReloaded", "1");
      //   location.reload();
      // }
    });

    const tabHandle = (e: number) => {
      tab.value = e;
      getStatistics(daterange.value);
    };

    const onChangeDateTab = (value) => {
      console.log("type", value);
      type.value = value;
      var date = new Date();
      if (value == "month") {
        currentMonth();
      }
      if (value == "lastMonth") {
        lastMonth();
      }
      if (value == "today") {
        getToday();
      }
      if (value == "yesterday") {
        getYesterday();
      }
      if(value == 'PreviousMonth') {
        getPreviousMonth();
      }
    };

    const currentMonth = async () => {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      daterange.value = [firstDay, lastDay];
      // getDashboard(daterange.value);
     
      const dateTitle = '(01 - '+new Date().getDate() + ' ' +new Date().toLocaleString('en-us',{ month:'long', year:'numeric'})+')';
      store.commit(Mutations.SET_DASHBOARD_DATE_TITLE, dateTitle)

      await getStatistics(daterange.value);

      CustomerService.getDueDateTaskCount(store, {
        agent: "all",
        customer_status_id: -1,
        end_date: "",
        keyword: "",
        lead_status_id: -1,
        per_page: "9999",
        start_date: "",
        task_due_dates: "all"
      });
    };

    const lastMonth = () => {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth() -1, 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
      daterange.value = [firstDay, lastDay];
      console.log('lastMonth',firstDay, lastDay)
      // getDashboard(daterange.value);

      const dateTitle = '(01 - '+lastDay.getDate() + ' ' +lastDay.toLocaleString('en-us',{ month:'long', year:'numeric'})+')';
      store.commit(Mutations.SET_DASHBOARD_DATE_TITLE, dateTitle)

      getStatistics(daterange.value);

      CustomerService.getDueDateTaskCount(store, {
        agent: "all",
        customer_status_id: -1,
        end_date: "",
        keyword: "",
        lead_status_id: -1,
        per_page: "9999",
        start_date: "",
        task_due_dates: "all"
      });
    };

    const getYesterday = () => {
      var date = new Date();
      var previous = new Date(date.getTime());
      previous.setDate(date.getDate() - 1);
      
      daterange.value = [previous, previous];
      // getDashboard(daterange.value);
      getStatistics(daterange.value);
    };

    const getToday = () => {
      var date = new Date();
      // let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      // let lastDay = new Date(date.getFullYear(), date.getMonth(), 1);
      daterange.value = [date, date];
      // getDashboard(daterange.value);
      getStatistics(daterange.value);
    };

    const getPreviousMonth = () => {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth() -2, 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() -1, 0);
      daterange.value = [firstDay, lastDay];
      console.log('previousMonth',firstDay, lastDay)
      // getDashboard(daterange.value);

      const dateTitle = '(01 - '+lastDay.getDate() + ' ' +lastDay.toLocaleString('en-us',{ month:'long', year:'numeric'})+')';
      store.commit(Mutations.SET_DASHBOARD_DATE_TITLE, dateTitle)

      getStatistics(daterange.value);

      CustomerService.getDueDateTaskCount(store, {
        agent: "all",
        customer_status_id: -1,
        end_date: "",
        keyword: "",
        lead_status_id: -1,
        per_page: "9999",
        start_date: "",
        task_due_dates: "all"
      });
    };

    const onChangeDatePicker = (listPeriod) => {
     if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
        var endTS = new Date(listPeriod[1]);
        const dateTitle = '('+startTS.getDate()+' '+startTS.toLocaleString('en-us',{ month:'long', year:'numeric'})+' - '+endTS.getDate() + ' ' +endTS.toLocaleString('en-us',{ month:'long', year:'numeric'})+')';
        store.commit(Mutations.SET_DASHBOARD_DATE_TITLE, dateTitle)

        getStatistics(listPeriod);
        type.value = "";
      }
      else
      {
          const nameRoute = router.currentRoute.value.name;
          if(user.value.role_id == 1 || nameRoute == 'admin-dashboard')
          {
            getToday();
          }
          else
          {
            type.value = 'month';
            currentMonth();
          }
      }
    };

    const onYearSelect = (event) => {
      let firstDay = new Date(event, 1 - 1);
      let lastDay = new Date(event, 12, 0);
      daterange.value = [firstDay, lastDay];
      console.log("daterange", daterange.value);
      getStatistics(daterange.value);
      type.value = "";
      // getDashboard(daterange.value);
    };

    const getDashboard = (daterange) => {
      if(daterange) {
        var startTS = new Date(daterange[0]);
        var endTS = new Date(daterange[1]);

        bodyParameters.value.start_date =
          startTS.getFullYear() +
          "-" +
          (startTS.getMonth() + 1) +
          "-" +
          startTS.getDate() +
          " 00:00:00";

        bodyParameters.value.end_date =
          endTS.getFullYear() +
          "-" +
          (endTS.getMonth() + 1) +
          "-" +
          endTS.getDate() +
          " 23:59:59";

          bodyParameters.value.type = "total-sales";
          getDashboardOverview(bodyParameters);
      } else {
        currentMonth();
      }

      

      bodyParameters.value.type = "total-new-sales";
      getDashboardOverview(bodyParameters);

      bodyParameters.value.type = "total-renewal-sales";
      getDashboardOverview(bodyParameters);

      bodyParameters.value.type = "total-llr-sales";
      getDashboardOverview(bodyParameters);

      bodyParameters.value.type = "total-lrl-sales";
      getDashboardOverview(bodyParameters);

      bodyParameters.value.type = "lifetime-renewal-leads";
      getDashboardOverview(bodyParameters);

      bodyParameters.value.type = "total-untouched-leads";
      getDashboardOverview(bodyParameters);

      bodyParameters.value.type = "total-refund";
      getDashboardOverview(bodyParameters);

      bodyParameters.value.type = "total-revenue";
      getDashboardOverview(bodyParameters);

      bodyParameters.value.type = "total-wip-sales";
      getDashboardOverview(bodyParameters);
    };

    const getDashboardOverview = (bodyParameters) => {
      Promise.all([
        DashboardService.getDashboardOverview(bodyParameters.value)
      ]).then((data) => {
        //  console.log('data',data)
      });
    };

    const loadDashboard = ref(false);
    const currentResponse = ref<any>(null);

    const getStatistics = async (daterange) => {
      // console.log(daterange)
      // if(tab.value == 1) {
      //   }
      loadDashboard.value = true;
      const nameRoute = router.currentRoute.value.name;

        if([1, 14, 15].includes(user.value.role_id) || nameRoute == 'admin-dashboard') {
          
            
           switch (tab.value) {
            case 1:
              await DashboardService.getSalesPerson(convertDates(daterange, tab.value));
              break;
            case 2:
              await DashboardService.getStatistics(convertDates(daterange,tab.value));
              break;
            case 3:
              await DashboardService.getOthers(convertDates(daterange, tab.value));
              break;
            default:
              getDashboard(daterange);
              break;
          }
        }

        // setTimeout(async() => {
          if ([2,9,13, 15].includes(user.value.role_id)) {
            await DashboardService.getSalesAgentManager(convertDates(daterange));
          }

          if (user.value.role_id == 4) {
          await DashboardService.getSalesUnderwriter(convertDates(daterange));
          }

          if ([7,11].includes(user.value.role_id)) {
            await DashboardService.getMarketingDashboard(convertDates(daterange));
          }
          if ([6].includes(user.value.role_id)) {
            await DashboardService.getAccountsDashboard(convertDates(daterange));
          }
          

        // }, 1000);
    };

    const convertDates = (dates, tab = 0) => {
      const startDate = dates[0];
      const endDate = dates[1];

      if(tab == 1)
      {
        return {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          table:'top-sales-person'
        };
      }
      else
      {
        return {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD")
        };
      }
      
    };

    let totalSales = computed(() => {
      return store.getters.getTotalSalesList;
    });
    let newSales = computed(() => {
      return store.getters.getNewSalesList;
    });
    let wipSales = computed(() => {
      return store.getters.getWIPSalesList;
    });

    let revenueSales = computed(() => {
      return store.getters.getRenewalSalesList;
    });

    let llrSales = computed(() => {
      return store.getters.getLlrSalesList;
    });

    let lrlSales = computed(() => {
      return store.getters.getLrlSalesList;
    });

    let lifetimeSales = computed(() => {
      return store.getters.getLifetimeSalesList;
    });

    let untouchedLeads = computed(() => {
      return store.getters.getUntouchedLeadsList;
    });

    let refund = computed(() => {
      return store.getters.getTotalRefundList;
    });

    let revenue = computed(() => {
      return store.getters.getTotalRevenueList;
    });

    const year = new Date().getFullYear();
    let yearsList = computed(() => {
      return Array.from(
        { length: year - 2017 },
        (value, index) => year - index
      );
    });
    let years = ref<IMultiselect>({
      placeholder: "Select Year",
      value: null,
      options: yearsList,
      searchable: true,
      clearOnSelect: true
    });

    const user = computed(() => {
      const current = window.localStorage.getItem('user');
      return JSON.parse(current || '');
    });

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    });

    const ladingAll = computed(() => {
      return store.getters.getLoadingAll;
    })

    const totalTodayTask = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.todays_task_count));
      }, 0);
    });

    const totalOverdue = computed(() => {
      return topSalesAgent.value.reduce((total, item) => {
        return (total += parseInt(item.over_due_tasks));
      }, 0);
    });

    const getPolicyCreatorStats = computed(() => {
      return store.getters.getPolicyCreatorStat;
    })

    const assignedToday = computed(() => {
      return getPolicyCreatorStats.value.reduce((total, item) => {
        return (total += item.assigned_today);
      }, 0);
    });

    const overDue = computed(() => {
      return getPolicyCreatorStats.value.reduce((total, item) => {
        return (total += item.overdue);
      }, 0);
    });

    const revenueLoading = computed(() => {
      return store.getters.getRevenueLoading;
    });

    const hasNoData = computed(() => {
      return store.getters.getHasNoData
    })

    const omPerformanceChart = computed(() => {
      return store.getters.getomPerformanceChart;
    });

    const hasOmSeries = computed(() => {
      if(omPerformanceChart.value)
        return omPerformanceChart.value.series.reduce((a,b) => a + b, 0)

      return 0
    })

    const topSalesPersonUpdated  = computed(() => {
            const datas = store.getters.getTopSalesAgent
            if (datas) {
                const value = datas.map((data) => {
                    return {
                        agent_name: data.agent_name,
                        total_leads: data.total_leads,
                        total_deals: data.total_deals,
                        new_leads: data.new_leads,
                        renewal_leads: data.renewal_leads,
                        llr_leads: data.llr_leads,
                        pending_leads: data.pending_leads,
                        untouched_customers: data.untouched_customers,
                        untouched_leads: data.untouched_leads,
                        over_due_tasks: data.over_due_tasks,
                        new_deals: data.new_deals,
                        renewal_deals: data.renewal_deals,
                        lrl_leads: data.lrl_leads,
                        lost_leads: data.lost_leads,
                        total_deals_cancelled: data.total_deals_cancelled,
                        sales_target: data.sales_target,
                        variance: data.variance,
                        quick_leads: data.quick_leads,
                        todays_task_count: data.todays_task_count,
                        current_month_tasks: data.current_month_tasks,
                        previous_month_tasks: data.previous_month_tasks,
                        cancellation_amount: data.cancellation_amount,
                        total_refund: data.total_refund,
                        deals_cancellation_payable_amount: currency(data.deals_cancellation_payable_amount),
                        total_revenue: currency(data.total_revenue),
                        closing_ratio_total: data.closing_ratio_total + '%', // (total_deals / total_leads) * 100
                        closing_ratio_new: data.closing_ratio_new + '%', // (new_deals / new_leads) * 100
                        closing_ratio_renewal: data.closing_ratio_renewal + '%', // (renewal_deals / renewal_leads) * 100
                        closing_ratio_lost_leads_and_lead_renewal_lost: data.closing_ratio_lost_leads_and_lead_renewal_lost + '%'
                    }
                })
                if(Object.keys(totalTopSalesAgent.value).length > 0) {
                    value.push(totalTopSalesAgent.value)
                }

                return value
                
            } else {
                return []
            }
        })

        const totalTopSalesAgent = computed(() => {
            const datas = store.getters.getTopSalesAgent
            if(datas) {
                const total_deals = calculateTotal(datas, 'total_deals')
                const total_leads = calculateTotal(datas, 'total_leads')
                const new_deals = calculateTotal(datas, 'new_deals')
                const new_leads = calculateTotal(datas, 'new_leads')
                const renewal_deals = calculateTotal(datas, 'renewal_deals')
                const renewal_leads = calculateTotal(datas, 'renewal_leads')
                const closing_total = roundUp((total_deals / total_leads) * 100)
                const closing_new = roundUp((new_deals / new_leads) * 100)
                const closing_renewal = roundUp((renewal_deals / renewal_leads) * 100)
                const quick_leads = calculateTotal(datas, 'quick_leads')
                const closing_ratio_ll_and_lrl = roundUp(((calculateTotal(datas, 'lost_leads') + calculateTotal(datas, 'lrl_leads')) / calculateTotal(datas, 'total_leads')) * 100)
                return {
                    agent_name: 'Total',
                    total_leads,
                    total_deals,
                    new_leads,
                    renewal_leads,
                    quick_leads,
                    variance: roundUp(calculateTotal(datas, 'variance')),
                    sales_target: calculateTotal(datas, 'sales_target'),
                    llr_leads: calculateTotal(datas, 'llr_leads'),
                    lrl_leads: calculateTotal(datas, 'lrl_leads'),
                    lost_leads: calculateTotal(datas, 'lost_leads'),
                    pending_leads: calculateTotal(datas, 'pending_leads'),
                    untouched_customers: calculateTotal(datas, 'untouched_customers'),
                    untouched_leads: calculateTotal(datas, 'untouched_leads'),
                    over_due_tasks: calculateTotal(datas, 'over_due_tasks'),
                    todays_task_count: calculateTotal(datas, 'todays_task_count'),
                    current_month_tasks: calculateTotal(datas, 'current_month_tasks'),
                    previous_month_tasks: calculateTotal(datas, 'previous_month_tasks'),
                    cancellation_amount: calculateTotal(datas, 'cancellation_amount').toFixed(2),
                    new_deals,
                    renewal_deals,
                    total_refund: calculateTotal(datas, 'total_refund'),
                    total_deals_cancelled: calculateTotal(datas, 'total_deals_cancelled'),
                    deals_cancellation_payable_amount: currency(roundUp(calculateTotal(datas, 'deals_cancellation_payable_amount'))),
                    total_revenue: currency(roundUp(calculateTotal(datas, 'total_revenue'))),
                    closing_ratio_total: isFinite(closing_total) ? `${roundUp(closing_total)}%` : `0.00%`,
                    closing_ratio_new: isFinite(closing_new) ? `${roundUp(closing_new)}%` : `0.00%`,
                    closing_ratio_renewal: isFinite(closing_renewal) ? `${roundUp(closing_renewal)}%` : `0.00%`,
                    closing_ratio_lost_leads_and_lead_renewal_lost: isFinite(closing_ratio_ll_and_lrl) ? `${roundUp(closing_ratio_ll_and_lrl)}%` : `0.00%`
                }
            }

            return {}
        })

        const calculateTotal = (data, column) => {
            return data.reduce((total, item) => {
                    if(typeof item[column] === 'string') {
                        const value = item[column].replace(',', '')
                        return total += parseFloat(value)
                    } else {
                        return total += item[column]
                    }
            }, 0)
        }

        const roundUp = (num) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

        const currency = (e) => {
          const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: registration.currency,
              maximumFractionDigits: 2,
          });

          return formatter.format(e).replace(registration.currency, "");
        }

    return {
      hasOmSeries,
      omLoading,
      topSalesPersonUpdated,
      omPerformanceChart,
      hasNoData,
      revenueLoading,
      router,
      overDue,
      assignedToday,
      totalTodayTask,
      totalOverdue,
      topSalesAgent,
      ladingAll,
      ElNotification,
      tabHandle,
      tab,
      user,
      getStatistics,
      onChangeDateTab,
      type,
      daterange,
      onChangeDatePicker,
      yearsList,
      years,
      getDashboard,
      totalSales,
      newSales,
      wipSales,
      revenueSales,
      llrSales,
      lrlSales,
      lifetimeSales,
      untouchedLeads,
      refund,
      revenue,
      onYearSelect,
      loadDashboard,
      currentResponse
    };
  }
});
